<template>
  <div class="mypage">
    <div class="container">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label>{{ id }}</label>
        </div>
      </header>
      <div class="registration-container">
        <h3 class="title">[会員情報]</h3>
        <form @submit.prevent="submitRegistration" class="registration-form">
          <div class="form-group">
            <label for="memberName" class="label-left">会員名</label>
            <input
              type="text"
              id="memberName"
              v-model="memberName"
              maxlength="100"
              required
            />
          </div>
          <div class="form-group">
            <label for="playerName" class="label-left">プレイヤー名</label>
            <input
              type="text"
              id="playerName"
              v-model="playerName"
              maxlength="100"
              required
            />
          </div>
          <!-- ログイン状態でなければパスワード入力欄を表示 -->
          <div v-if="!id" class="form-group">
            <label for="password" class="label-left">パスワード</label>
            <input
              type="password"
              id="password"
              v-model="password"
              maxlength="20"
              required
            />
          </div>
          <div v-if="!id" class="form-group">
            <label for="confirmPassword" class="label-left"
              >パスワード（確認）</label
            >
            <input
              type="password"
              id="confirmPassword"
              v-model="confirmPassword"
              maxlength="20"
              required
            />
          </div>
          <div class="form-group">
            <label for="tel" class="label-left">電話番号</label>
            <input
              type="text"
              id="tel"
              v-model="tel"
              @input="validateTel"
              maxlength="20"
              required
            />
          </div>
          <div class="form-group">
            <label for="email" class="label-left">メールアドレス</label>
            <input
              type="email"
              id="email"
              v-model="email"
              maxlength="100"
              required
            />
          </div>
          <div class="form-group">
            <label class="label-left">生年月日</label>
            <div class="date-selectors">
              <select v-model="birthYear" required>
                <option value="" disabled selected>年</option>
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
              <span>年</span>
              <select v-model="birthMonth" required>
                <option value="" disabled selected>月</option>
                <option v-for="month in months" :key="month" :value="month">
                  {{ month }}
                </option>
              </select>
              <span>月</span>
              <select v-model="birthDay" required>
                <option value="" disabled selected>日</option>
                <option v-for="day in days" :key="day" :value="day">
                  {{ day }}
                </option>
              </select>
              <span>日</span>
            </div>
          </div>
          <div class="form-group-radio-button">
            <label class="label-left">性別</label>
            <div class="radio-buttons">
              <label>
                <input type="radio" value="0" v-model="gender" />
                未選択
              </label>
              <label>
                <input type="radio" value="1" v-model="gender" />
                男性
              </label>
              <label>
                <input type="radio" value="2" v-model="gender" />
                女性
              </label>
            </div>
          </div>
          <br />
          <div v-if="!id" class="form-group">
            <label>
              <input type="checkbox" v-model="agreed" />
              E.W.Cの<a
                href="/#/regulation"
                target="_blank"
                style="color: yellow"
                >レギュレーション</a
              >に<br />遵守します。
            </label>
          </div>
          <div class="form-group" v-if="id && !changePassword">
            <button class="password-button" @click="changePassword = true">
              パスワードを変更する
            </button>
          </div>
          <div v-if="changePassword" class="form-group">
            <label for="curPassword" class="label-left">現在のパスワード</label>
            <input
              type="password"
              id="curPassword"
              v-model="curPassword"
              maxlength="20"
            />
          </div>
          <div v-if="changePassword" class="form-group">
            <label for="newPassword" class="label-left">新しいパスワード</label>
            <input
              type="password"
              id="newPassword"
              v-model="newPassword"
              maxlength="20"
            />
          </div>
          <div v-if="changePassword" class="form-group">
            <label for="confirmNewPassword" class="label-left"
              >新しいパスワード（確認）</label
            >
            <input
              type="password"
              id="confirmNewPassword"
              v-model="confirmNewPassword"
              maxlength="20"
            />
          </div>
          <div class="form-group">
            <p style="color: red">{{ warningMsg }}</p>
            <button
              type="submit"
              :disabled="!id && !agreed"
              class="register-button"
            >
              登録する
            </button>
            <button type="button" class="back-button" @click="back">
              戻る
            </button>
          </div>
          <div v-if="confirmDialog" class="modal">
            <div class="modal-content">
              <p>登録しますか？</p>
              <button @click="executeRegistration">はい</button>
              <button @click="toggleConfirmDialog">いいえ</button>
            </div>
          </div>
          <div v-if="resultDialog" class="modal">
            <div class="modal-content">
              <div v-html="resultMessage"></div>
              <button @click="handleResultButtonClick">OK</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import {
  generateRandomString,
  formatPhoneNumberToE164,
  getQueryParam,
  checkLoginInfo,
} from "@/modules/common";
import {
  API_STATUS,
  META_PIXEL_INIT_SCRIPT,
  GOOGLE_TAG_CONVERSION_REGIST_SCRIPT,
} from "@/modules/constants";

export default {
  data() {
    return {
      memberName: null,
      playerName: null,
      password: null,
      confirmPassword: null,
      curPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      tel: null,
      email: null,
      gender: "0",
      id: null,
      agreed: false,

      warningMsg: "",

      confirmDialog: false,
      resultDialog: false,
      resultMessage: null,
      success: false,
      changePassword: false,

      fbclid: null,

      birthYear: null,
      birthMonth: null,
      birthDay: null,
      years: Array.from(
        { length: 101 },
        (_, i) => new Date().getFullYear() - (100 - i)
      ),
      months: Array.from({ length: 12 }, (_, i) => i + 1),
      days: [],
    };
  },
  watch: {
    birthYear() {
      this.updateDays();
    },
    birthMonth() {
      this.updateDays();
    },
  },
  async mounted() {
    await this.initialize();

    if (getQueryParam("backpress")) {
      this.back();
      return;
    }

    // Metaピクセル用のイベントID発行
    this.pageViewId = "rgsview_" + generateRandomString(9);
    this.registrationId = "rgscomp_" + generateRandomString(9);

    // Metaピクセルのスクリプトをロードする
    const metaPixelScript = document.createElement("script");
    metaPixelScript.innerHTML = META_PIXEL_INIT_SCRIPT;
    document.head.appendChild(metaPixelScript);

    // Googleタグのスクリプトをロードする
    const googleTagScript = document.createElement("script");
    googleTagScript.innerHTML = GOOGLE_TAG_CONVERSION_REGIST_SCRIPT;
    document.head.appendChild(googleTagScript);

    // クリックIDをロードする
    this.fbclid = localStorage.getItem("fbclid");
    const newFbclid = getQueryParam("fbclid");
    if (newFbclid) {
      this.fbclid = newFbclid;
      localStorage.setItem("fbclid", this.fbclid);
    }

    // ページビューイベントを送信
    setTimeout(() => {
      this.sendPageViewEvent();
    }, 1);
  },
  computed: {
    birthdate() {
      if (this.birthYear && this.birthMonth && this.birthDay) {
        return Number(
          `${this.birthYear}${String(this.birthMonth).padStart(2, "0")}${String(
            this.birthDay
          ).padStart(2, "0")}`
        );
      }
      return null;
    },
  },
  methods: {
    updateDays() {
      const year = this.birthYear;
      const month = this.birthMonth;
      if (year && month) {
        const daysInMonth = new Date(year, month, 0).getDate();
        this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        if (this.birthDay > daysInMonth) {
          this.birthDay = null;
        }
      } else {
        this.days = [];
      }
    },
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.id = sessionUserId;
        await this.getMemberInfo(sessionUserId, login_hash, login_time);
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });

        if (response.Status === API_STATUS.NORMAL) {
          this.memberName = response.Data.vmemnm;
          this.playerName = response.Data.vplynm;
          this.tel = response.Data.vtel;
          this.email = response.Data.vmail;
          const [year, month, day] = response.Data.dbrth.split("-");
          this.birthYear = parseInt(year);
          this.birthMonth = parseInt(month);
          this.birthDay = parseInt(day);
          this.gender =
            response.Data.ngen != null ? response.Data.ngen.toString() : null;
        } else {
          console.error("会員情報の取得に失敗しました。" + response.Status);
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    submitRegistration() {
      // ダイアログが表示されている場合は何もしない
      if (this.confirmDialog) return;

      // 確認パスワード不一致
      if (
        (this.password && this.password !== this.confirmPassword) ||
        (this.newPassword && this.newPassword !== this.confirmNewPassword)
      ) {
        this.resultDialog = true;
        this.resultMessage = "パスワードが確認用と一致しません。";
        return;
      }

      // ダイアログを表示
      this.toggleConfirmDialog();
    },
    async executeRegistration() {
      try {
        // 会員登録のAPI呼び出し
        const response = await APIClient.get("set_1member.php", {
          vmemnm: this.memberName,
          vplynm: this.playerName,
          vpw: this.password,
          vtel: this.tel,
          vmail: this.email,
          dbrth: this.birthdate,
          ngen: this.gender,
          vid: this.id,
          vcpw: this.curPassword,
          vnpw: this.newPassword,
        });

        console.log(response); // レスポンスをログに出力
        if (response.Status == API_STATUS.NORMAL) {
          if (!this.id) {
            this.resultMessage =
              "登録が完了しました。<br>会員ID：" +
              response.Data.vid +
              "<br>パスワード：" +
              response.Data.vpw;
            this.success = true;

            // 画面遷移後、登録したIDとパスワードが自動入力されるように記録する
            localStorage.setItem("savedUserId", response.Data.vid);
            localStorage.setItem("savedPassword", response.Data.vpw);

            // イベントを送信
            this.sendRegistrationEvent();
          } else {
            this.resultMessage = "登録が完了しました。";
            this.success = true;
          }
        } else if (response.Status == API_STATUS.ALERT) {
          this.resultMessage = response.Message;
        } else {
          this.resultMessage = "エラーが発生しました。";
        }
      } catch (error) {
        console.error(error); // エラーをログに出力
        this.submitMessage = "エラーが発生しました。";
      } finally {
        // ダイアログを切替
        this.confirmDialog = false;
        this.toggleResultDialog();
      }
    },
    // 登録ダイアログの表示切り替え
    toggleConfirmDialog() {
      this.confirmDialog = !this.confirmDialog;
    },
    // 結果ダイアログの表示切り替え
    toggleResultDialog() {
      this.resultDialog = !this.resultDialog;
    },
    // 戻る
    back() {
      const url = this.id ? "/my-page" : "/login-my-page";
      this.$router.push(url);
    },
    handleResultButtonClick() {
      if (this.success) {
        this.back();
      } else {
        // 失敗時はモーダルを閉じる
        this.resultDialog = false;
      }
    },
    // 電話番号入力の整形
    validateTel() {
      if (this.tel) {
        // 数値と"-"以外の文字を削除
        this.tel = this.tel.replace(/[^\d-]/g, "");
      }
    },

    // ページビューイベント送信
    async sendPageViewEvent() {
      // Pixel
      window.fbq(
        "track",
        "PageView",
        {
          content_type: "registration",
        },
        { eventID: this.pageViewId }
      );

      // コンバージョンAPI
      try {
        const response = await APIClient.get("post_conversion.php", {
          eventName: "PageView",
          contentType: "registration",
          eventSourceUrl: window.location.href,
          eventId: this.pageViewId,
          fbclid: this.fbclid,
        });
        if (response.Status != API_STATUS.NORMAL) {
          console.error("コンバージョンAPIの送信に失敗");
        }
      } catch (error) {
        console.error("コンバージョンAPIの送信に失敗", error);
      }
    },

    // 登録イベント送信
    async sendRegistrationEvent() {
      // Pixel
      window.fbq(
        "track",
        "CompleteRegistration",
        {
          content_name: this.playerName,
          content_category: "ewc_registration_complete",
          content_type: "registration",
          value: 0,
          currency: "JPY",
        },
        { eventID: this.registrationId }
      );

      const phone_e164 = formatPhoneNumberToE164(this.tel);

      // コンバージョンAPI
      try {
        const response = await APIClient.get("post_conversion.php", {
          eventName: "CompleteRegistration",
          contentName: this.playerName,
          contentCategory: "ewc_registration_complete",
          contentType: "registration",
          email: this.email,
          phone: phone_e164,
          gender: this.gender == "1" ? "m" : this.gender == "2" ? "f" : null,
          eventSourceUrl: window.location.href,
          eventId: this.registrationId,
          value: 0,
          currency: "JPY",
          fbclid: this.fbclid,
        });
        if (response.Status != API_STATUS.NORMAL) {
          console.error("コンバージョンAPIの送信に失敗");
        }
      } catch (error) {
        console.error("コンバージョンAPIの送信に失敗", error);
      }

      // Googleタグ
      window.gtag_report_conversion(this.email, phone_e164);
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.form-group-radio-button {
  margin-bottom: 20px;
}

.form-group-radio-button label {
  margin-right: 10px;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.radio-buttons {
  display: flex;
}

.radio-buttons label {
  margin-right: 20px;
}

.back-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  transition: background-color 0.3s, color 0.3s;
}

.back-button:hover {
  background-color: white;
  color: black;
}

.password-button {
  background-color: white;
  border: 2px solid white;
  color: black;
}
</style>
