<template>
  <div class="mypage">
    <div v-if="!failed && initialized" class="container">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label>{{ vid }}：{{ vplynm }}</label>
        </div>
      </header>
      <div class="reservation-container">
        <h3 class="title">[マイ予約]</h3>
        <p
          :class="{
            tentative: isPreReserve,
            confirmed: !isPreReserve,
          }"
        >
          {{ isPastReserve ? "" : isPreReserve ? "予約" : "予約確定" }}
        </p>
        <button
          v-if="!editMode && isPreReserve && !isPastReserve"
          @click="editMode = true"
          class="edit-button"
        >
          編集する
        </button>
        <button
          v-if="editMode && isPreReserve"
          @click="toggleCancelDialog"
          class="cancel-button"
        >
          編集をキャンセル
        </button>
        <form @submit.prevent="submitBooking" class="reservation-form">
          <div class="form-group">
            <label for="reservationName" class="label-left">予約名</label>
            <input
              type="text"
              id="reservationName"
              v-model="reservationName"
              :class="{ disabled: true }"
              :readonly="true"
              maxlength="100"
              required
            />
          </div>
          <div class="form-group">
            <label for="reservationDate" class="label-left">予約日</label>
            <input
              type="date"
              id="reservationDate"
              v-model="reservationDate"
              :min="today"
              :class="{ disabled: true }"
              :readonly="true"
              required
            />
          </div>
          <div class="form-group">
            <label for="tel" class="label-left">電話番号</label>
            <input
              type="text"
              id="tel"
              v-model="tel"
              @input="validateTel"
              maxlength="20"
              :class="{ disabled: true }"
              :readonly="true"
              required
            />
          </div>
          <div class="form-group">
            <label for="gameUsage" class="label-left">利用ゲーム設定</label>
            <select
              id="gameUsage"
              v-model="gameUsage"
              :class="{ disabled: !editMode }"
              :readonly="!editMode"
              required
            >
              <option value="0">未定</option>
              <option value="1">サバゲー</option>
              <option value="2">ナーフ</option>
              <option value="3">UAB</option>
              <option value="4">試射場</option>
            </select>
          </div>
          <div class="form-group">
            <label for="enteringUsage" class="label-left">入店予定時刻</label>
            <select
              id="enteringUsage"
              v-model="enteringUsage"
              :class="{ disabled: !editMode }"
              :readonly="!editMode"
              required
            >
              <option value="0">未定</option>
              <option value="1">8:30</option>
              <option value="2">9:00</option>
              <option value="3">9:30</option>
              <option value="4">10:00</option>
              <option value="5">10:30</option>
              <option value="6">11:00</option>
              <option value="7">11:30</option>
              <option value="8">12:00</option>
              <option value="9">12:30</option>
              <option value="10">13:00</option>
              <option value="11">13:30</option>
              <option value="12">14:00</option>
              <option value="13">14:30</option>
              <option value="14">15:00</option>
              <option value="15">15:30</option>
              <option value="19">17:30</option>
              <option value="20">18:00</option>
              <option value="21">18:30</option>
              <option value="22">19:00</option>
              <option value="23">19:30</option>
              <option value="24">20:00</option>
            </select>
          </div>
          <div class="form-group">
            <label for="facilityUsage" class="label-left">施設利用設定</label>
            <select
              id="facilityUsage"
              v-model="facilityUsage"
              :class="{ disabled: !editMode }"
              :readonly="!editMode"
              required
            >
              <option value="0">未定</option>
              <option value="1">全日</option>
              <option value="2">半日</option>
              <option value="3">1時間</option>
              <option value="4">2時間</option>
              <option value="5">3時間</option>
            </select>
          </div>
          <div class="form-group">
            <label for="numberOfPeople" class="label-left">予約人数</label>
            <input
              :class="{ disabled: !editMode }"
              :readonly="!editMode"
              type="number"
              id="numberOfPeople"
              v-model="numberOfPeople"
              min="1"
              max="30"
              maxlength="2"
              class="item-input"
              required
            />
          </div>
          <br />
          <h3 class="title">[レンタル品]</h3>
          <div class="form-group">
            <div class="item">
              <label for="rentalPack" class="label-left">レンタルパック</label>
              <input
                :class="{ disabled: !editMode }"
                :readonly="!editMode"
                type="number"
                id="rentalPack"
                v-model="rentalPack"
                min="0"
                max="9"
                maxlength="1"
                class="item-input"
                required
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <div class="item">
              <label for="goggles" class="label-left">フェイスマスク</label>
              <input
                :class="{ disabled: !editMode }"
                :readonly="!editMode"
                type="number"
                id="goggles"
                v-model="goggles"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="item">
              <label for="gloves" class="label-left">手袋</label>
              <input
                :class="{ disabled: !editMode }"
                :readonly="!editMode"
                type="number"
                id="gloves"
                v-model="gloves"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="item">
              <label for="electricGuns" class="label-left">レンタルガン</label>
              <input
                :class="{ disabled: !editMode }"
                :readonly="!editMode"
                type="number"
                id="electricGuns"
                v-model="electricGuns"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="item">
              <label for="nband" class="label-left">チームマーカー</label>
              <input
                :class="{ disabled: !editMode }"
                :readonly="!editMode"
                type="number"
                id="nband"
                v-model="nband"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div v-if="false" class="form-group">
            <div class="item">
              <label for="speedReducers" class="label-left"
                >減速アダプター</label
              >
              <input
                :class="{ disabled: !editMode }"
                :readonly="!editMode"
                type="number"
                id="speedReducers"
                v-model="speedReducers"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div v-if="false" class="form-group">
            <div class="item">
              <label for="handGuns" class="label-left">ハンドガン</label>
              <input
                :class="{ disabled: !editMode }"
                :readonly="!editMode"
                type="number"
                id="handGuns"
                v-model="handGuns"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div v-if="false" class="form-group">
            <div class="item">
              <label for="lunchBoxes" class="label-left">お弁当</label>
              <input
                :class="{ disabled: !editMode }"
                :readonly="!editMode"
                type="number"
                id="lunchBoxes"
                v-model="lunchBoxes"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label for="reservationMemo" class="label-left" maxlength="500"
              >メモ</label
            >
            <textarea
              :class="{ disabled: !editMode }"
              :readonly="!editMode"
              id="reservationMemo"
              v-model="reservationMemo"
            ></textarea>
            <p v-if="isPastReserve" class="warning">
              こちらのご予約は過去の日程のため内容を変更出来ません。
            </p>
            <p v-if="!isPreReserve && !isPastReserve" class="warning">
              こちらのご予約は確定済みです。内容の変更やキャンセルが必要な場合は店舗へお電話をお願いします。
            </p>
            <p v-if="isPreReserve && !isPastReserve" class="warning">
              予約名、予約日、電話番号の変更やご予約のキャンセルが必要な場合は店舗へお電話をお願いします。
            </p>
          </div>
          <div v-if="confirmDialog" class="modal">
            <div class="modal-content">
              <p>予約内容の変更を実行しますか？</p>
              <button @click="executeBooking">はい</button>
              <button @click="toggleConfirmDialog">いいえ</button>
            </div>
          </div>
          <div v-if="resultDialog" class="modal">
            <div class="modal-content">
              <div v-html="resultMessage"></div>
              <button @click="resultOKButton">OK</button>
            </div>
          </div>
          <div v-if="cancelDialog" class="modal">
            <div class="modal-content">
              <p>入力内容は保存されません。変更をキャンセルしますか？</p>
              <button @click="executeCancel">はい</button>
              <button @click="toggleCancelDialog">いいえ</button>
            </div>
          </div>

          <button v-if="editMode" type="submit" class="reserve-button">
            変更する
          </button>
        </form>
      </div>
    </div>
  </div>
  <div v-if="failed" class="modal">
    <div class="modal-content">
      <p>
        予約情報が見つかりませんでした。再度予約リストから選択してください。
      </p>
      <button @click="back">OK</button>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import {
  convertDateToNumber,
  getQueryParam,
  checkLoginInfo,
} from "@/modules/common";
import { API_STATUS } from "@/modules/constants";

export default {
  name: "editReserve",
  props: ["nfldno", "drsvdt", "vtel"],
  data() {
    return {
      loadedData: null,

      vid: null,
      vmemnm: null,
      vplynm: null,

      reservationName: null,
      reservationDate: null,
      tel: null,
      gameUsage: "0",
      enteringUsage: "0",
      facilityUsage: "0",
      numberOfPeople: 1,
      goggles: 0,
      gloves: 0,
      electricGuns: 0,
      speedReducers: 0,
      handGuns: 0,
      lunchBoxes: 0,
      ntracer: 0,
      reservationMemo: null,

      isPreReserve: false,

      confirmDialog: false,
      resultDialog: false,
      cancelDialog: false,
      resultMessage: null,

      success: false,
      failed: false,
      initialized: false,

      editMode: false,
    };
  },
  async mounted() {
    if (getQueryParam("backpress")) {
      this.back();
      return;
    }
    await this.initialize();
    this.initialized = true;
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        await this.getDetailInfo(
          sessionUserId,
          login_hash,
          login_time,
          this.$route.params.nfldno,
          this.$route.params.drsvdt,
          this.$route.params.vtel
        );
      }
    },
    async getDetailInfo(vid, login_hash, login_time, nfldno, drsvdt, vtel) {
      try {
        const response = await APIClient.get("get_reserve_detail.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
          nfldno: nfldno,
          drsvdt: drsvdt,
          vtel: vtel,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.loadedData = response.Data;
          this.setReserveData(this.loadedData);
        } else {
          this.failed = true;
          console.error("予約情報の取得に失敗しました。");
        }
      } catch (error) {
        this.failed = true;
        console.error("予約情報の取得に失敗しました。", error);
      }
    },
    setReserveData(data) {
      this.vmemnm = data.ndmemnm;
      this.vplynm = data.ndmemnm;
      this.reservationName = data.ndmemnm;
      this.tel = data.vtel;
      this.reservationDate = data.drsvdt;
      this.facilityUsage = data.nrsv;
      this.gameUsage = data.ngame;
      this.enteringUsage = data.nentry;
      this.numberOfPeople = data.nnum;
      this.goggles = data.nmask;
      this.gloves = data.nglove;
      this.electricGuns = data.ngun1;
      this.speedReducers = data.nadapter;
      this.handGuns = data.ngun2;
      this.lunchBoxes = data.nlunch;
      this.ntracer = data.ntracer;
      this.nband = data.nband;
      this.reservationMemo = data.vcntus;
      this.isPreReserve = data.npre == 1;

      this.calcRentalPackCount();
    },
    async submitBooking() {
      // ダイアログが表示されている場合は何もしない
      if (this.confirmDialog) return;

      // ダイアログを表示
      this.toggleConfirmDialog();
    },
    // 仮予約ダイアログの表示切り替え
    toggleConfirmDialog() {
      this.confirmDialog = !this.confirmDialog;
    },
    // 結果ダイアログの表示切り替え
    toggleResultDialog() {
      this.resultDialog = !this.resultDialog;
    },
    // キャンセルダイアログの表示切り替え
    toggleCancelDialog() {
      this.cancelDialog = !this.cancelDialog;
    },
    // resultでのOKボタン押下
    resultOKButton() {
      this.toggleResultDialog();
      if (this.success) {
        this.back();
      }
    },
    // 変更の実行
    async executeBooking() {
      try {
        const response = await APIClient.get("update_reserv.php", {
          // 送信する予約情報
          vid: this.vid,
          login_hash: localStorage.getItem("login_hash"),
          login_time: localStorage.getItem("login_time"),
          nfldno: 1,
          drsvdt: convertDateToNumber(this.reservationDate),
          ndmemnm: this.reservationName,
          nnum: this.numberOfPeople,
          vtel: this.tel,
          ngame: this.gameUsage,
          nentry: this.enteringUsage,
          nrsv: this.facilityUsage,
          nmask: this.goggles + this.rentalPack,
          ngun1: this.electricGuns + this.rentalPack,
          ngun2: this.handGuns,
          nglove: this.gloves + this.rentalPack,
          nadapter: this.speedReducers,
          nlunch: this.lunchBoxes,
          nband: this.nband + this.rentalPack,
          ntracer: this.ntracer,
          vcntus: this.reservationMemo,
        });

        console.log(response); // レスポンスをログに出力
        if (response.Status == API_STATUS.NORMAL) {
          this.resultMessage = "変更が完了しました。";
          this.success = true;
        } else if (response.Status == API_STATUS.ALERT) {
          this.resultMessage = "正常に更新できませんでした。";
        } else {
          console.log(response.Message);
          this.resultMessage = "エラーが発生しました。";
        }
      } catch (error) {
        console.error(error); // エラーをログに出力
        // ここでエラー時の処理を追加
        this.resultMessage = "エラーが発生しました。";
      } finally {
        // ダイアログを切替
        this.confirmDialog = false;
        this.toggleResultDialog();
      }
    },
    // 編集のキャンセル
    executeCancel() {
      this.setReserveData(this.loadedData);
      this.toggleCancelDialog();
      this.cancelDialog = false;
      this.editMode = false;
    },
    // 電話番号入力の整形
    validateTel() {
      // 数値と"-"以外の文字を削除
      this.tel = this.tel.replace(/[^\d-]/g, "");
    },
    // 戻る
    back() {
      this.$router.push("/reserve-list");
    },

    // レンタルパックの数をカウントして反映
    calcRentalPackCount() {
      // 対象となるプロパティのリスト
      const properties = [
        this.goggles,
        this.electricGuns,
        this.gloves,
        this.nband,
      ];

      // 各プロパティの最小値を求める
      this.rentalPack = Math.min(...properties);

      // 各プロパティから rentalPack を減算
      this.goggles -= this.rentalPack;
      this.electricGuns -= this.rentalPack;
      this.gloves -= this.rentalPack;
      this.nband -= this.rentalPack;
    },
  },
  computed: {
    today() {
      return new Date().toISOString().split("T")[0];
    },
    isPastReserve() {
      if (!this.reservationDate) return false;
      return new Date(this.reservationDate) < new Date(this.today);
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.reservation-container {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
  width: 80%;
}
.back {
  color: white;
  text-align: left;
  cursor: pointer;
  width: fit-content;
}

.back:hover {
  border-bottom: 1px solid #fff; /* 下線を追加 */
}

button {
  max-width: 300px;
  width: 95%;
}

.edit-button {
  background-color: rgb(179, 228, 197);
  margin-bottom: 50px;
}

.cancel-button {
  background-color: rgb(180, 180, 180);
  margin-bottom: 50px;
}

.warning {
  color: white;
  font-size: 15px;
  font-weight: bold;
}

input.disabled,
textarea.disabled,
select.disabled {
  background-color: #f0f0f0;
  color: #333;
  pointer-events: none;
  border: 1px solid #ccc;
}

input[readonly],
textarea[readonly],
select[readonly] {
  background-color: #cfcece;
  color: #333;
  border: 1px solid #ccc;
}

.tentative {
  color: #e99802;
  font-size: 18px;
  font-weight: bold;
}

.confirmed {
  color: rgb(0, 255, 0);
  font-size: 18px;
  font-weight: bold;
}
</style>
