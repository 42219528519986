<template>
  <div class="div14" id="div14" name="div14">
    <header v-if="!fromHomePage">
      <div class="header-elements">
        <h4 class="back" @click="goback">＜ 戻る</h4>
      </div>
    </header>
    <div v-if="!fromHomePage" class="header-margin"></div>
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">フィールド利用料金</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content  height-auto">
      <div class="sd content height-auto align-items-center">
        <div class="sd content-t3 align-items-center ">
          <img src="images/ゲーム料金.JPG" />
        </div>
      </div>
      ※サバゲー(10歳以上)に18歳未満のみで参加される場合は「保護者承諾書」をダウンロードし、ご持参ください。<br/>
      <p class="sd text text-head1"> 
        <br /><a href="pdf/保護者承諾書.pdf" download @click="openPage()">保護者承諾書</a>
        </p><br/>
    </div>
  </div>
  <div class="div23" id="div23" name="div23">
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">貸切料金</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content height-auto align-items-center">
        <div class="sd content-t3 align-items-center ">
          <img src="images/貸切料金.JPG" />
        </div>
    </div> 
  </div>
  <div class="div15" id="div15" name="div15">
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">レンタル</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content">
      <div class="sd content-t1">
        <div class="sd content-t2 width_50">
          <div class="sd content-t3">
            <p class="sd text text-head1">レンタルパック</p>
            <div class="sd space-50"></div>
            <div class="sd content-t4-s3">
              <p class="sd text text-value1 text-align-left">
                ・レンタルパック１
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">
                  （レンタルガン,フェイスマスク,手袋,チームマーカー）
                  <br />全日…￥2,000
                  <br />半日…￥1,000
                </p>
              </div>
              <p class="sd text text-value1 text-align-left">
                ・レンタルパック２
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">
                 （レンタルガン,トレーサー,フェイスマスク,手袋,チームマーカー）
                  <br />￥2,500
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="sd content-t2 width_50">
          <div class="sd content-t3">
            <p class="sd text text-head1">レンタル単体</p>
            <div class="sd space-50"></div>
            <div class="sd content-t4-s3">
              <p class="sd text text-value1 text-align-left">
                ・レンタルガン(ハイキャパE)
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">￥1,500</p>
              </div>
              <p class="sd text text-value1 text-align-left">
                ・レンタルガン(スコーピオンMOD.M)
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">￥2,000</p>
              </div>
              <p class="sd text text-value1 text-align-left">・トレーサー</p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">
                  ￥500<br />
                </p>
              </div>
              <p class="sd text text-value1 text-align-left">
                ・フェイスマスク
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">￥500</p>
              </div>
              <p class="sd text text-value1 text-align-left">
                ・手袋(買い切り)
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">￥100</p>
              </div>
            </div>
          </div>
        </div>
        <div class="sd content-t2 rental-image">
          <div class="rental-image-container">
            <div class="rental-image-text">スコーピオン<br />MOD.M</div>
            <img
              src="images/スコーピオンMOD_M（ドットが打てないため_を使用しています。）.jpg"
            />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text"><br />チームマーカー</div>
            <img src="images/チームマーカー.jpg" />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text">ハイキャパE</div>
            <img src="images/ハイキャパE.jpg" />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text">フェイスマスク</div>
            <img src="images/フェイスマスク.jpg" />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text">手袋</div>
            <img src="images/手袋.jpg" />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text">トレーサー</div>
            <img src="images/トレーサー.jpg" />
          </div>
        </div>
      </div>
      <div class="sd" id="description">
        <p class="text text-standard10 text-align-left">
          ※レンタル品には数に限りがございます。予めご了承下さい。
        </p>
        <p class="text text-standard10 text-align-left">
          ※レンタルの場合のBB弾に関してましては現地にてご購入、もしくは持参でお願い致します。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fromHomePage: Boolean,
  },
  name: "PriceView",
  methods: {
    goback() {
      this.$router.push("/my-page");
    },    
    openPage() {
      window.open('pdf/保護者承諾書.pdf', '_blank')
    },    
    openmoneyPage() {
      window.open('pdf/料金改定案.pdf', '_blank')
    },
  },
};
</script>
<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.header-elements {
  background-color: white;
}
.header-elements .back {
  color: black;
}
.back:hover {
  border-bottom: 1px solid black;
}
.header-margin {
  margin-top: 20px;
}
</style>
