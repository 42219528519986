<template>
  <div class="mypage">
    <div class="container">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label v-if="vid">{{ vid }}：{{ vplynm }}</label>
        </div>
      </header>
      <div class="reservation-container">
        <h3 class="title">[来店予約]</h3>
        <form @submit.prevent="submitBooking" class="reservation-form">
          <div class="form-group">
            <label for="reservationName" class="label-left">予約名</label>
            <input
              type="text"
              id="reservationName"
              v-model="reservationName"
              :class="{ disabled: vid !== null }"
              :readonly="vid !== null"
              maxlength="100"
              required
            />
          </div>
          <div class="form-group">
            <label class="label-left">予約日</label>
            <div class="date-selectors">
              <select v-model="reservationYear" required>
                <option value="" disabled selected>年</option>
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
              <span>年</span>
              <select v-model="reservationMonth" required>
                <option value="" disabled selected>月</option>
                <option v-for="month in months" :key="month" :value="month">
                  {{ month }}
                </option>
              </select>
              <span>月</span>
              <select v-model="reservationDay" required>
                <option value="" disabled selected>日</option>
                <option v-for="day in days" :key="day" :value="day">
                  {{ day }}
                </option>
              </select>
              <span>日</span>
            </div>
          </div>
          <div class="form-group">
            <label for="tel" class="label-left">電話番号</label>
            <input
              type="text"
              id="tel"
              v-model="tel"
              @input="validateTel"
              maxlength="20"
              required
            />
          </div>
          <div class="form-group">
            <label for="gameUsage" class="label-left">利用ゲーム設定</label>
            <select id="gameUsage" v-model="gameUsage" required>
              <option value="0">未定</option>
              <option value="1">サバゲー</option>
              <option value="2">ナーフ</option>
              <option value="3">UAB</option>
              <option value="4">試射場</option>
            </select>
          </div>
          <div class="form-group">
            <label for="enteringUsage" class="label-left">入店予定時刻</label>
            <select id="enteringUsage" v-model="enteringUsage" required>
              <option value="0">未定</option>
              <option value="1">8:30</option>
              <option value="2">9:00</option>
              <option value="3">9:30</option>
              <option value="4">10:00</option>
              <option value="5">10:30</option>
              <option value="6">11:00</option>
              <option value="7">11:30</option>
              <option value="8">12:00</option>
              <option value="9">12:30</option>
              <option value="10">13:00</option>
              <option value="11">13:30</option>
              <option value="12">14:00</option>
              <option value="13">14:30</option>
              <option value="14">15:00</option>
              <option value="15">15:30</option>
              <option value="19">17:30</option>
              <option value="20">18:00</option>
              <option value="21">18:30</option>
              <option value="22">19:00</option>
              <option value="23">19:30</option>
              <option value="24">20:00</option>
            </select>
          </div>
          <div class="form-group">
            <label for="facilityUsage" class="label-left">施設利用時間</label>
            <select id="facilityUsage" v-model="facilityUsage" required>
              <option value="0">未定</option>
              <option value="1">全日</option>
              <option value="2">半日</option>
              <option value="3">1時間</option>
              <option value="4">2時間</option>
              <option value="5">3時間</option>
            </select>
          </div>
          <div class="form-group">
            <label for="numberOfPeople" class="label-left">予約人数</label>
            <input
              type="number"
              id="numberOfPeople"
              v-model="numberOfPeople"
              min="1"
              max="30"
              maxlength="2"
              class="item-input"
              required
            />
          </div>
          <br />
          <h3 class="title">[レンタル品]</h3>
          <div class="form-group">
            <div class="item">
              <label for="rentalPack" class="label-left">レンタルパック</label>
              <input
                type="number"
                id="rentalPack"
                v-model="rentalPack"
                min="0"
                max="9"
                maxlength="1"
                class="item-input"
                required
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <div class="item">
              <label for="goggles" class="label-left">フェイスマスク</label>
              <input
                type="number"
                id="goggles"
                v-model="goggles"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="item">
              <label for="gloves" class="label-left">手袋</label>
              <input
                type="number"
                id="gloves"
                v-model="gloves"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="item">
              <label for="electricGuns" class="label-left">レンタルガン</label>
              <input
                type="number"
                id="electricGuns"
                v-model="electricGuns"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="item">
              <label for="nband" class="label-left">チームマーカー</label>
              <input
                type="number"
                id="nband"
                v-model="nband"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div v-if="false" class="form-group">
            <div class="item">
              <label for="speedReducers" class="label-left"
                >減速アダプター</label
              >
              <input
                type="number"
                id="speedReducers"
                v-model="speedReducers"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div v-if="false" class="form-group">
            <div class="item">
              <label for="handGuns" class="label-left">ハンドガン</label>
              <input
                type="number"
                id="handGuns"
                v-model="handGuns"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <div v-if="false" class="form-group">
            <div class="item">
              <label for="lunchBoxes" class="label-left">お弁当</label>
              <input
                type="number"
                id="lunchBoxes"
                v-model="lunchBoxes"
                min="0"
                max="30"
                maxlength="2"
                class="item-input"
                required
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label for="reservationMemo" class="label-left" maxlength="500"
              >メモ</label
            >
            <textarea id="reservationMemo" v-model="reservationMemo"></textarea>
          </div>
          <div v-if="confirmDialog" class="modal">
            <div class="modal-content">
              <p>予約を実行しますか？</p>
              <button @click="executeBooking">はい</button>
              <button @click="toggleConfirmDialog">いいえ</button>
            </div>
          </div>
          <div v-if="resultDialog" class="modal">
            <div class="modal-content">
              <div v-html="resultMessage"></div>
              <button @click="resultOKButton">OK</button>
            </div>
          </div>

          <p class="warning">
            定員オーバー等の問題がある場合は電話をお掛けすることがあります。
            <br/>ご注意ください。
          </p>
          <p class="warning">
            ※ 予約キャンセルは直接お電話で受け付けしております。
          <br />通常のフィールドご利用の場合、<br />キャンセル料は無料です。
          <br />貸切のご利用の場合、開催7日前までに<br />お知らせいただくと無料です。
          </p>
          <p class="warning">
            ※ 3営業日以内に返信をさせていただきます。ご了承ください。
          </p>

          <button type="submit" class="reserve-button">予約する</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import {
  generateRandomString,
  formatPhoneNumberToE164,
  getQueryParam,
  checkLoginInfo,
} from "@/modules/common";
import {
  API_STATUS,
  META_PIXEL_INIT_SCRIPT,
  GOOGLE_TAG_CONVERSION_SCHEDULE_SCRIPT,
} from "@/modules/constants";

export default {
  name: "temporaryBooking",
  props: ["selectedDate", "fromMyPage"],
  created() {
    this.paramDate = this.$route.params.selectedDate;
    this.myPage = this.$route.params.fromMyPage == 1;
  },
  data() {
    return {
      vid: null,
      vmemnm: null,
      vplynm: null,

      reservationName: null,

      reservationYear: null,
      reservationMonth: null,
      reservationDay: null,

      tel: null,
      facilityUsage: "0",
      gameUsage: "0",
      enteringUsage:"0",
      numberOfPeople: 1,
      goggles: 0,
      gloves: 0,
      electricGuns: 0,
      speedReducers: 0,
      handGuns: 0,
      lunchBoxes: 0,
      nband: 0,
      ntracer: 0,

      rentalPack: 0,

      reservationMemo: null,
      confirmDialog: false,
      resultDialog: false,
      resultMessage: null,
      success: false,
      pageViewId: null,
      viewContentId: null,
      scheduleId: null,

      vmail: null,
      dbrth: null,
      ngen: null,

      fbclid: null,

      paramDate: null,
      myPage: false,

      years: [new Date().getFullYear(), new Date().getFullYear() + 1],
      months: [],
      days: [],
    };
  },
  watch: {
    reservationYear() {
      this.updateMonths();
      this.updateDays();
    },
    reservationMonth() {
      this.updateDays();
    },
  },
  async mounted() {
    const [year, month, day] = this.paramDate.split("-");
    this.reservationYear = parseInt(year);
    this.updateMonths();
    this.reservationMonth = parseInt(month);
    this.updateDays();
    this.reservationDay = parseInt(day);
    await this.initialize();
    if (getQueryParam("backpress")) {
      this.back();
    }
  },
  methods: {
    updateMonths() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1; // 月は0ベースなので1を足す
      if (this.reservationYear === currentYear) {
        this.months = Array.from(
          { length: 12 - currentMonth + 1 },
          (_, i) => currentMonth + i
        );
      } else {
        this.months = Array.from({ length: 12 }, (_, i) => i + 1);
      }
    },
    updateDays() {
      const year = this.reservationYear;
      const month = this.reservationMonth;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // 月は0ベースなので1を足す
      const currentDay = currentDate.getDate();

      if (year && month) {
        const daysInMonth = new Date(year, month, 0).getDate();
        if (year === currentYear && month === currentMonth) {
          this.days = Array.from(
            { length: daysInMonth - currentDay + 1 },
            (_, i) => currentDay + i
          );
        } else {
          this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        }
        if (this.reservationDay > daysInMonth) {
          this.reservationDay = null;
        }
      } else {
        this.days = [];
      }
    },
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        this.getMemberInfo(sessionUserId, login_hash, login_time);
      }

      // Metaピクセル用のイベントID発行
      this.pageViewId = "rsvview_" + generateRandomString(9);
      this.viewContentId = "rsvclick_" + generateRandomString(9);
      this.scheduleId = "rsvcomp_" + generateRandomString(9);

      // Metaピクセルのスクリプトをロードする
      const metaPixelScript = document.createElement("script");
      metaPixelScript.innerHTML = META_PIXEL_INIT_SCRIPT;
      document.head.appendChild(metaPixelScript);

      // Googleタグのスクリプトをロードする
      const googleTagScript = document.createElement("script");
      googleTagScript.innerHTML = GOOGLE_TAG_CONVERSION_SCHEDULE_SCRIPT;
      document.head.appendChild(googleTagScript);

      // クリックIDをロードする
      this.fbclid = localStorage.getItem("fbclid");
      const newFbclid = getQueryParam("fbclid");
      if (newFbclid) {
        this.fbclid = newFbclid;
        localStorage.setItem("fbclid", this.fbclid);
      }

      // ページビューイベントを送信
      setTimeout(() => {
        this.sendPageViewEvent();
      }, 1);
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.vmemnm = response.Data.vmemnm;
          this.vplynm = response.Data.vplynm;
          this.reservationName = response.Data.vplynm;
          this.tel = response.Data.vtel;

          this.vmail = response.Data.vmail;
          this.dbrth = response.Data.dbrth;
          this.ngen = response.Data.ngen;
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    async submitBooking() {
      // ダイアログが表示されている場合は何もしない
      if (this.confirmDialog) return;

      // ダイアログを表示
      this.toggleConfirmDialog();
    },
    // 仮予約ダイアログの表示切り替え
    toggleConfirmDialog() {
      this.confirmDialog = !this.confirmDialog;
    },
    // 結果ダイアログの表示切り替え
    toggleResultDialog() {
      this.resultDialog = !this.resultDialog;
    },
    // resultでのOKボタン押下
    resultOKButton() {
      this.toggleResultDialog();
      if (this.success) {
        this.back();
      }
    },
    // 仮予約の実行
    async executeBooking() {
      try {
        const response = await APIClient.get("set_reserv.php", {
          // 送信する予約情報
          vid: this.vid,
          nfldno: 1,
          drsvdt: this.reservationDate,
          ndmemnm: this.reservationName,
          nnum: this.numberOfPeople,
          vtel: this.tel,
          nrsv: this.facilityUsage,
          ngame: this.gameUsage,
          nentry: this.enteringUsage,
          nmask: this.goggles + this.rentalPack,
          ngun1: this.electricGuns + this.rentalPack,
          ngun2: 0,
          nglove: this.gloves + this.rentalPack,
          nadapter: this.speedReducers,
          nlunch: this.lunchBoxes,
          nband: this.nband + this.rentalPack,
          ntracer: this.ntracer,
          vcntus: this.reservationMemo,
          npre: 1,
        });

        console.log(response); // レスポンスをログに出力
        if (response.Status == API_STATUS.NORMAL) {
          this.resultMessage =
            "予約登録が完了しました。<br><br>予約名：" +
            this.reservationName +
            "<br>予約日：" +
            this.reservationYear +
            "年" +
            this.reservationMonth +
            "月" +
            this.reservationDay +
            "日";
          "<br>電話番号：" + this.tel;
          this.success = true;

          // Meta用にイベントを送信
          this.sendScheduleEvent();
        } else if (response.Status == API_STATUS.ALERT) {
          this.resultMessage = response.Message;
        } else {
          this.resultMessage = "エラーが発生しました。";
        }
      } catch (error) {
        console.error(error); // エラーをログに出力
        // ここでエラー時の処理を追加
        this.resultMessage = "エラーが発生しました。";
      } finally {
        // ダイアログを切替
        this.confirmDialog = false;
        this.toggleResultDialog();
      }
    },
    // 電話番号入力の整形
    validateTel() {
      // 数値と"-"以外の文字を削除
      this.tel = this.tel.replace(/[^\d-]/g, "");
    },
    // 戻る
    back() {
      if (this.paramDate) {
        if (this.myPage) {
          this.$router.push({
            name: "ReserveCalendar",
            params: { fromMyPage: 1 },
          });
        } else {
          this.$router.push("/calendar");
        }
      } else {
        const url = this.vid ? "/reserve-select" : "/";
        this.$router.push(url);
      }
    },

    // ページビューイベント送信
    async sendPageViewEvent() {
      // Pixel
      window.fbq(
        "track",
        "PageView",
        {
          content_type: "reserve",
        },
        { eventID: this.pageViewId }
      );

      // コンバージョンAPI
      try {
        const response = await APIClient.get("post_conversion.php", {
          eventName: "PageView",
          contentType: "reserve",
          eventSourceUrl: window.location.href,
          eventId: this.pageViewId,
          fbclid: this.fbclid,
        });
        if (response.Status != API_STATUS.NORMAL) {
          console.error("コンバージョンAPIの送信に失敗");
        }
      } catch (error) {
        console.error("コンバージョンAPIの送信に失敗", error);
      }
    },
    // コンテンツビューイベント送信
    async sendViewContentEvent() {
      // Pixel
      window.fbq(
        "track",
        "ViewContent",
        {
          content_name: this.reservationName,
          content_category: "ewc_button_click",
          content_type: "reserve",
        },
        { eventID: this.viewContentId }
      );

      // コンバージョンAPI
      try {
        const response = await APIClient.get("post_conversion.php", {
          eventName: "ViewContent",
          contentName: this.reservationName,
          contentCategory: "ewc_button_click",
          contentType: "reserve",
          eventSourceUrl: window.location.href,
          eventId: this.viewContentId,
          fbclid: this.fbclid,
        });
        if (response.Status != API_STATUS.NORMAL) {
          console.error("コンバージョンAPIの送信に失敗");
        }
      } catch (error) {
        console.error("コンバージョンAPIの送信に失敗", error);
      }
    },
    // 予約イベント送信
    async sendScheduleEvent() {
      // Pixel
      window.fbq(
        "track",
        "Schedule",
        {
          content_name: this.reservationName,
          content_category: "ewc_reserve_complete",
          content_type: "reserve",
          schedule_date: this.reservationDate,
          facility_usage: this.facilityUsage,
          game_usage: this.gameUsage,
          entering_usage: this.enteringUsage,
          number_of_people: this.numberOfPeople,
        },
        { eventID: this.scheduleId }
      );

      const phone_e164 = formatPhoneNumberToE164(this.tel);

      // コンバージョンAPI
      try {
        const response = await APIClient.get("post_conversion.php", {
          eventName: "Schedule",
          contentName: this.reservationName,
          contentCategory: "ewc_reserve_complete",
          contentType: "reserve",
          phone: phone_e164,
          email: this.vmail,
          dateOfBirth: this.dbrth,
          gender: this.ngen == "1" ? "m" : this.ngen == "2" ? "f" : null,

          scheduleDate: this.reservationDate,
          facilityUsage: this.facilityUsage,
          gameUsage: this.gameUsage,
          enteringUsage: this.enteringUsage,
          numberOfPeople: this.numberOfPeople,
          eventSourceUrl: window.location.href,
          eventId: this.scheduleId,
          fbclid: this.fbclid,
        });
        if (response.Status != API_STATUS.NORMAL) {
          console.error("コンバージョンAPIの送信に失敗");
        }
      } catch (error) {
        console.error("コンバージョンAPIの送信に失敗", error);
      }

      // Googleタグ
      window.gtag_report_conversion(this.vmail, phone_e164);
    },
  },
  computed: {
    today() {
      return new Date().toISOString().split("T")[0];
    },
    reservationDate() {
      if (
        this.reservationYear &&
        this.reservationMonth &&
        this.reservationDay
      ) {
        return Number(
          `${this.reservationYear}${String(this.reservationMonth).padStart(
            2,
            "0"
          )}${String(this.reservationDay).padStart(2, "0")}`
        );
      }
      return null;
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.reservation-container {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
  width: 80%;
}
.back {
  color: white;
  text-align: left;
  cursor: pointer;
  width: fit-content;
}

.back:hover {
  border-bottom: 1px solid #fff; /* 下線を追加 */
}

button {
  max-width: 300px;
  width: 95%;
}

.warning {
  color: white;
  font-size: 15px;
  font-weight: bold;
}

input.disabled {
  background-color: #f0f0f0;
  color: #333;
  pointer-events: none;
  border: 1px solid #ccc;
}

input[readonly] {
  background-color: #cfcece;
  color: #333;
  border: 1px solid #ccc;
}
</style>
