<template>
  <div class="mypage">
    <div class="container">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label>{{ vid }}：{{ vplynm }}</label>
        </div>
      </header>
      <div class="reserve-select-container">
        <h3 class="title">[マイ予約一覧]</h3>
        <h4 class="title">今後の予約</h4>
        <div class="bars-container">
          <div
            class="bar"
            v-for="future in list_future"
            :key="future.drsvdt"
            v-on:click="goToDetail(future)"
          >
            <p
              :class="{
                tentative: future.npre == 1,
                confirmed: future.npre != 1,
              }"
            >
              {{ future.npre == 1 ? "予約" : "予約確定" }}
            </p>
            <p>
              {{ future.drsvdt_show }} - {{ future.nrsv }} - {{ future.nnum }}人
            </p>
          </div>
        </div>
        <h4 class="title">過去の予約</h4>
        <div class="bars-container">
          <div
            class="bar"
            v-for="past in list_past"
            :key="past.drsvdt"
            v-on:click="goToDetail(past)"
          >
            <p>{{ past.drsvdt_show }} - {{ past.nrsv }} - {{ past.nnum }}人</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";
import { checkLoginInfo, logout, getQueryParam } from "@/modules/common";

export default {
  data() {
    return {
      vid: null,
      vmemnm: null,
      vplynm: null,
      list_future: [],
      list_past: [],
    };
  },
  mounted() {
    if (getQueryParam("backpress")) {
      this.back();
      return;
    }

    this.initialize();
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        await this.getMemberInfo(sessionUserId, login_hash, login_time);
        await this.getReserveList(this.vid, login_hash, login_time);
      } else {
        logout();
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.vmemnm = response.Data.vmemnm;
          this.vplynm = response.Data.vplynm;
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    async getReserveList(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_reserve_list.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.list_future = response.Data.future;
          this.list_past = response.Data.past;
          console.log(response.Data);
        } else {
          console.error("予約一覧の取得に失敗しました。");
        }
      } catch (error) {
        console.error("予約一覧の取得に失敗しました。", error);
      }
    },
    goToDetail(data) {
      this.$router.push({
        name: "editReserve",
        params: {
          nfldno: data.nfldno,
          drsvdt: data.drsvdt,
          vtel: data.vtel,
        },
      });
    },
    // 戻る
    back() {
      this.$router.push("/reserve-select");
    },
  },
  // 戻る
  back() {
    this.$router.push("/reserve-select");
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.back {
  color: white;
  text-align: left;
  cursor: pointer;
  width: fit-content;
}

.back:hover {
  border-bottom: 1px solid #fff; /* 下線を追加 */
}

.bars-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

.bar {
  background-color: white;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.bar p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.tentative {
  color: #e99802;
}

.confirmed {
  color: green;
}
</style>
